import ReactDOM from 'react-dom'
import React, { useRef, useEffect, useState } from 'react'
import { extend, Canvas, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, TransformControls } from 'three-stdlib'
import * as THREE from 'three';

import ResourceTracker from '../common/ResourceTracker';
import {colorPalette} from '../common/Util';

extend({OrbitControls})

const DEFAULT_BG_COLOR = 0xeeeeee;

const loader = new THREE.TextureLoader();
const palletMaterials = [
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-2.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-2.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-1.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-1.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-2.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-2.png')}),
];

const tracker = new ResourceTracker();

function track(obj) {
  return tracker.track(obj);
}

function clearScene() {
  tracker.dispose();
}

let glGlobal;
let sceneGlobal;
let cameraGlobal;
let scaleGlobal;

function screenshot() {
  if (glGlobal && sceneGlobal && cameraGlobal) {
    glGlobal.render(sceneGlobal, cameraGlobal);
    return glGlobal.domElement.toDataURL();
  }
}

function setScale(scale) {
  scaleGlobal = scale;
}

function setCameraPostion(camPosition) {
  if (cameraGlobal) {
    cameraGlobal.position.set(camPosition.width, camPosition.height, camPosition.depth);
  }
}

function drawContainer(length, width, height, showBase=true, baseWidth=1000, baseDepth=1000) {
  if (! sceneGlobal) return;
  const l = length * scaleGlobal;
  const w = width * scaleGlobal;
  const h = height * scaleGlobal;

  const geometry = track(new THREE.BoxGeometry(l, h, w));
  const edgesGeometry = track(new THREE.EdgesGeometry(geometry));
  const lineBasicMaterial = track(new THREE.LineBasicMaterial({color: 0x000}));
  const lineSegments = track(new THREE.LineSegments(edgesGeometry, lineBasicMaterial));
  sceneGlobal.add(lineSegments);

  if (showBase) {
    const baseGeometry = track(new THREE.BoxGeometry(baseWidth, 2, baseDepth));
    const baseMaterial = track(new THREE.MeshBasicMaterial({
      color: '#fff',
      opacity: 0.5,
      transparent: true,
    }));
    const baseMesh = track(new THREE.Mesh(baseGeometry, baseMaterial));
    baseMesh.position.set(0, -(h / 2) - 2, 0);
    sceneGlobal.add(baseMesh);
  }
}

function drawBox(box, color, offsets) {
  if (! sceneGlobal) return;
  const length = box.length * scaleGlobal;
  const width = box.width * scaleGlobal;
  const height = box.height * scaleGlobal;
  const x = box.x * scaleGlobal + (length / 2) - offsets.length * scaleGlobal;
  const y = box.y * scaleGlobal + (width / 2) - offsets.width * scaleGlobal;
  const z = box.z * scaleGlobal + (height / 2) - offsets.height * scaleGlobal;
  drawAbsBox({
    length,
    width,
    height,
    x,
    y,
    z,
  }, color);
}

function drawAbsBox(box, color) {
  if (! sceneGlobal) return;
  const material = track(new THREE.MeshLambertMaterial({color: color}));
  const geometry = track(new THREE.BoxGeometry(box.length - 1, box.height - 1, box.width - 1));
  const mesh = track(new THREE.Mesh(geometry, material));
  mesh.position.set(box.x, box.z, box.y);
  sceneGlobal.add(mesh);

  const material2 = track(new THREE.LineBasicMaterial({color: 0x000, linewidth: 1}));
  const geometry2 = track(new THREE.BoxGeometry(box.length, box.height, box.width));
  geometry2.translate(box.x, box.z, box.y);
  const edges = track(new THREE.EdgesGeometry(geometry2));
  const line = track(new THREE.LineSegments(edges, material2));
  sceneGlobal.add(line);
}

function drawPallet(pallet, offsets) {
  if (! sceneGlobal) return;
  const length = pallet.length * scaleGlobal;
  const width = pallet.width * scaleGlobal;
  const height = pallet.height * scaleGlobal;
  const pHeight = pallet.palletHeight * scaleGlobal;

  const x = pallet.x * scaleGlobal + (length / 2) - offsets.length * scaleGlobal;
  const y = pallet.y * scaleGlobal + (width / 2) - offsets.width * scaleGlobal;
  const z = pallet.z * scaleGlobal + (pHeight / 2) - offsets.height * scaleGlobal;

  const geometry = track(new THREE.BoxGeometry(length - 1, pHeight - 1, width - 1));
  const mesh = track(new THREE.Mesh(geometry, palletMaterials));
  mesh.position.set(x, z, y);
  sceneGlobal.add(mesh);

  const material2 = track(new THREE.LineBasicMaterial({color: 0x000, linewidth: 1}));
  const geometry2 = track(new THREE.BoxGeometry(length, pHeight, width));
  geometry2.translate(x, z, y);
  const edges = track(new THREE.EdgesGeometry(geometry2));
  const line = track(new THREE.LineSegments(edges, material2));
  sceneGlobal.add(line);

  drawBoxesInsidePallet(pallet, offsets);
}

function drawBoxesInsidePallet(pallet, offsets) {
  const swap = pallet.width > pallet.length;
  const palletOffset = {
    x: pallet.x * scaleGlobal + (pallet.length / 2) - offsets.length * scaleGlobal,
    y: pallet.y * scaleGlobal + (pallet.width / 2) - offsets.width * scaleGlobal,
    z: pallet.z * scaleGlobal + (pallet.height / 2) - offsets.height * scaleGlobal,
  };

  pallet.boxes.forEach(b => {
    const color = colorPalette(pallet.id);

    if (swap) {
      let temp = b.width;
      b.width = b.length;
      b.length = temp;
      temp = b.x;
      b.x = b.y;
      b.y = temp;
    }

    let box = {
      length: b.length * scaleGlobal,
      width: b.width * scaleGlobal,
      height: b.height * scaleGlobal,
      x: b.x * scaleGlobal + b.length / 2 * scaleGlobal + palletOffset.x,
      y: b.y * scaleGlobal + b.width / 2 * scaleGlobal + palletOffset.y,
      z: b.z * scaleGlobal + b.height / 2 * scaleGlobal + palletOffset.z + pallet.palletHeight * scaleGlobal,
    };

    drawAbsBox(box, color);
  });
}

function setBg(color) {
  if (sceneGlobal) {
    sceneGlobal.background = new THREE.Color(color);
  }
}

function setDefaultBg() {
  if (sceneGlobal) {
    sceneGlobal.background = new THREE.Color(DEFAULT_BG_COLOR);
  }
}

function ThreeScene(props) {
  const orbit = useRef()
  const {camera, scene, gl} = useThree()
  const [ref, set] = useState()

  glGlobal = gl;
  sceneGlobal = scene;
  cameraGlobal = camera;

  scene.background = new THREE.Color(DEFAULT_BG_COLOR);

  useFrame(() => orbit.current.update())
  // const axesHelper = new THREE.AxesHelper( 500 );
  // scene.add( axesHelper );

  return (
    <>
      <orbitControls ref={orbit} args={[camera, gl.domElement]}
        minDistance="30" maxDistance="1500" enableDamping
        enableZoom={true} screenSpacePanning />
      <ambientLight intensity={0.5} />
      <spotLight position={[1700, 1700, -1700]} angle={0.3} />
    </>
  )
}

export {
  ThreeScene,
  screenshot,
  setScale,
  clearScene,
  setCameraPostion,
  drawContainer,
  drawBox,
  drawPallet,
  setBg,
  setDefaultBg,
};
