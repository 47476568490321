import React from "react";

function Footer() {
  return (
    <div>
      <footer className="px-5 py-3 text-sm">
        Copyright &copy; Red Jasper Holdings 2021
      </footer>
    </div>
  );
}

export default Footer;
