import {ReactComponent as LeftArrow} from '../common/Sideways_Arrow_left_Icon.svg';
import {ReactComponent as RightArrow} from '../common/Sideways_Arrow_right_Icon.svg';

function ContainerPager(props) {
  const btnClassName = "p-0 w-8 h-8 bg-red-600 rounded-full hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none";

  function preContainer() {
    if (props.total <= 1) return;
    if (props.current > 0) {
      props.gotoContainer(props.current - 1);
    } else {
      props.gotoContainer(props.total - 1);
    }
  }

  function nextContainer() {
    if (props.total <= 1) return;
    if (props.current < (props.total - 1)) {
      props.gotoContainer(props.current + 1);
    } else {
      props.gotoContainer(0);
    }
  }

  return <>
    <button onClick={preContainer} className={btnClassName + ' mr-3'}>
      <LeftArrow className="w-6 h-6 inline-block" style={{position: 'relative', top: '-1px'}} />
    </button>

    {props.current + 1} of {props.total} {props.containerName}(s)

    <button onClick={nextContainer} className={btnClassName + ' ml-3'}>
      <RightArrow className="w-6 h-6 inline-block" style={{position: 'relative', top: '-1px'}} />
    </button>
  </>
}


export default ContainerPager;
