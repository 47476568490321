
import React, {useState} from "react";
import * as THREE from 'three';
import {Canvas} from '@react-three/fiber'

import {colorPalette, convertHexToRGBA} from '../common/Util';
import * as ThreeD from './ThreeScene';
import FileUploader from './FileUploader';
import ContainerPager from './ContainerPager';
import Calculator from './Calculator';
import ScreenshotButton from './ScreenshotButton';

const timeoutIdSet = new Set();

function clearTimeoutIdSet() {
  for (let id of timeoutIdSet) {
    clearTimeout(id);
  }
}

function volumeOfBoxes(boxes) {
  let sum = 0.0;
  boxes?.forEach(box => {
    sum += box.length * box.width * box.height;
  });
  return sum.toFixed(2);
}

function totalContainerVol(list) {
  let containerVolume = 0;
  list.forEach((c, i) => {
    containerVolume += c.container.length * c.container.width * c.container.height;
  });
  return containerVolume;
}

function containerSize(c) {
  if (!c) return '';
  return `${c.length.toFixed(2)}x${c.width.toFixed(2)}x${c.height.toFixed(2)}`;
}

function containerVolume(c) {
  if (!c) return '';
  let volume = c.length * c.width * c.height;
  return `${volume.toFixed(2)}`;
}

function ContainerPlanner(props) {
  const defaultData = {
    version: '1.0',
    lastTotalVolume: 0,
    list: [
      {
        container: {
          length: 0.0,
          width: 0.0,
          height: 0.0,
        },
        boxes: [],
        pallets: [],
        utilization: 0.0,
      },
    ],
    boxTypes: [],
    current: 0,
  };

  const [data, setData] = useState(defaultData);
  const [enabledButton, setEnabledButton] = useState(true);
  const [demo, setDemo] = useState('none');

  function onSelectFile(file) {
    if (file) {
      setEnabledButton(false);
      setData(defaultData);

      if (file.name.endsWith('.json')) {
        onGetJsonFile(file);
        return;
      }

      let data = new FormData();
      data.append('file', file);
      fetch('/engine-multiple/', {
        method: 'POST',
        body: data,
      })
      .then(res => {
        if (!res.ok) {
          throw Error('server error.');
        }
        return res.json();
      })
      .then((data) => {
        reloadData(data, 0);
      })
      .catch(err => console.log(err))
      .finally(() => setEnabledButton(true));
    }
  }

  function onGetJsonFile(file) {
    let reader = new FileReader();
    reader.onload = function(){
      let json = reader.result;
      let data = JSON.parse(json)
      reloadData(data, 0);
      setEnabledButton(true);
    };
    reader.readAsText(file);
  }

  function showSummaryInfo() {
    if (props.showSummaryInfo) {
      return (
        <div className="text-left text-sm mt-3">
          <CartonizationSummary data={data} containerName={props.containerName}
            unit={props.unit} cubicUnit={props.cubicUnit} />
        </div>
      );
    }
  }

  function showCalculator() {
    if (props.showCalculator) {
      return (
        <div className="text-left text-sm mt-3">
          <Calculator totalVol={totalContainerVol(data.list).toFixed(2)}
            originalVol={data.lastTotalVolume.toFixed(2)} />
        </div>
      );
    }
  }

  function showContainerPager() {
    if (props.showPager) {
      return (
        <div className="absolute left-1 bottom-3">
          <ContainerPager current={data.current} total={data.list.length}  gotoContainer={gotoContainer} containerName={props.containerName} />
        </div>
      );
    }
  }

  function showCaseOptions() {
    if (props.unit === 'in.') {
      return <AppliedCaseOptions />;
    } else {
      return <ContainerCaseOptions />;
    }
  }

  function onDemoChange(event) {
    let lastTotalVolume = totalContainerVol(data.list);
    setDemo(event.target.value);
    setData(defaultData);
    reloadData(defaultData, 0);
    if (event.target.value === 'none') return;
    fetch('/data/' + event.target.value)
    .then(res => res.json())
    .then((data) => {
      reloadData(data, lastTotalVolume);
    })
  }

  function gotoContainer(index) {
    let newData = {...data};
    newData.current = index;
    setData(newData);
    drawAll(newData);
  }

  function reloadData(loadedData, lastTotalVolume) {
      loadedData.current = 0;
      loadedData.lastTotalVolume = lastTotalVolume;
      handleLagacyData(loadedData);
      transformSizes(loadedData, 0.01);
      setData(loadedData);
      insertPalletsForExample(loadedData);
      // drawAll(loadedData);
  }

  function insertPalletsForExample(loadedData) {
    let count = 0;
    function callCallback() {
      count ++;
      if (count == 41) {
        drawAll(loadedData, false, 500);
      }
    }

    const nameMapping = {
      1: 'carton-1-5.json',
      2: 'carton-6-10.json',
      3: 'carton-11-19.json',
      4: 'carton-21.json',
      5: 'carton-22.json',
      6: 'carton-23-27.json',
      7: 'carton-29-34.json',
      8: 'carton-35.json',
      9: 'carton-36-40.json',
    };

    if (loadedData.key === "example-1-c1") {
      let pallets = loadedData.list[0].boxes;
      loadedData.list[0].boxes = [];
      loadedData.list[0].pallets = pallets;
      pallets.forEach(pallet => {
        pallet.palletHeight = 0.15;
        let fileName = '/data/container-packing/' + nameMapping[pallet.id];
        fetch(fileName)
        .then(res => {
          return res.json();
        })
        .then(data => {
          let factor = 1000;
          if (data.list[0].container.length > 200) {
            factor = 1000;
          } else if (data.list[0].container.length > 20) {
            factor = 100;
          }

          pallet.boxes = data.list[0].boxes;
          pallet.boxes.forEach(b => {
            b.length = b.length / factor;
            b.width = b.width / factor;
            b.height = b.height / factor;
            b.x = b.x / factor;
            b.y = b.y / factor;
            b.z = b.z / factor;
          });
          callCallback();
        });
      });
    } else {
      drawAll(loadedData);
    }
  }

  function drawAll(loadedData, showBase=true, delay=100) {
    clearTimeoutIdSet();
    const selected = loadedData.list[loadedData.current];
    const c = selected.container;
    const offsets = {
      length: c.length / 2,
      width: c.width / 2,
      height: c.height / 2,
    }
    ThreeD.clearScene();
    ThreeD.setScale(props.scale);
    ThreeD.setCameraPostion(props.camPosition);
    ThreeD.drawContainer(c.length, c.width, c.height, showBase);
    selected.boxes?.forEach((box, i) => {
      if (delay > 0) {
        const id = setTimeout(() => ThreeD.drawBox(box, colorPalette(box.id), offsets), i * delay);
        timeoutIdSet.add(id);
      } else {
        ThreeD.drawBox(box, colorPalette(box.id), offsets);
      }
    });
    selected.pallets?.forEach((pallet, i) => {
      if (delay > 0) {
        const id = setTimeout(() => ThreeD.drawPallet(pallet, offsets), i * delay);
        timeoutIdSet.add(id);
      } else {
        ThreeD.drawPallet(pallet, offsets);
      }
    });
  }

  function handleLagacyData(loadedData) {
    if (loadedData.hasOwnProperty('version')) return;

    loadedData.boxTypes = loadedData.boxTypes.map(t => ({
      id: t.id,
      length: t.w,
      width: t.d,
      height: t.h,
      lengthFlag: null,
      widthFlag: null,
      heightFlag: null,
      total: t.total,
    }));

    loadedData.list = loadedData.list.map(o => {
      let newO = {
        utilization: o.utilization,
      };
      newO.container = {
        length: o.container.width,
        width: o.container.depth,
        height: o.container.height,
      };

      newO.boxes = o.boxes.map(b => ({
        id: b.id,
        x: b.x,
        z: b.y,
        y: b.z,
        length: b.w,
        width: b.d,
        height: b.h,
      }));

      return newO;
    });
  }

  function transformSizes(originalData, times) {
    originalData.boxTypes.forEach(t => {
      t.length = t.length * times;
      t.width = t.width * times;
      t.height = t.height * times;
    });

    const transformBoxes = (b => {
      b.x = b.x * times;
      b.y = b.y * times;
      b.z = b.z * times;
      b.length = b.length * times;
      b.width = b.width * times;
      b.height = b.height * times;
    });

    originalData.list.forEach(c => {
      c.container.length = c.container.length * times;
      c.container.width = c.container.width * times;
      c.container.height = c.container.height * times;

      c.boxes?.forEach(transformBoxes);

      c.pallets?.forEach(p => {
        p.x = p.x * times;
        p.y = p.y * times;
        p.z = p.z * times;
        p.length = p.length * times;
        p.width = p.width * times;
        p.height = p.height * times;
        p.palletHeight = p.palletHeight * times;
        p.boxes?.forEach(transformBoxes);
      });

    });
  }

  function takeScreenshotImidiatly() {
    ThreeD.setBg(0xffffff);
    let img = ThreeD.screenshot();
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = img;
    downloadLink.target = '_self';
    downloadLink.download = 'screenshot.png';
    downloadLink.click();
    ThreeD.setDefaultBg();

    setTimeout(() => {
      document.body.removeChild(downloadLink);
    }, 100);
  }

  function takeScreenshot() {
    if (data.key) {
      takeScreenshotImidiatly();
      return;
    }

    drawAll(data, false, 0);

    setTimeout(() => {
      takeScreenshotImidiatly();
    }, 200);

    setTimeout(() => {
      drawAll(data, true, 0);
    }, 200);
  }

  return (
    <div className="w-full relative" style={{height: '500px'}}>
      <Canvas antialias={false} camera={{fov: 50, near: 1, far: 2300}} >
        <ThreeD.ThreeScene />
      </Canvas>

      <div className="text-left text-sm absolute top-2 right-2">
        <CurrentContainerInfo data={data}
          containerName={props.containerName}
          unit={props.unit} cubicUnit={props.cubicUnit} />
      </div>

      <div className="absolute right-1 bottom-3">
        <ScreenshotButton onClick={takeScreenshot} />
        <FileUploader handleFile={onSelectFile} enabled={enabledButton} />
      </div>

      <div className="absolute left-1 top-2">
        <label className="text-sm mr-2" htmlFor="demos">Choose a case:</label>
        <select className="focus:outline-none text-sm px-2 rounded " disabled={!enabledButton} name="demos" id="demos"
          onChange={onDemoChange} value={demo}>
          <option value="none">Reset</option>
          {showCaseOptions()}
        </select>

        {showSummaryInfo()}
        {showCalculator()}

      </div>

      {showContainerPager()}

    </div>
  );
}

function CartonizationSummary(props) {
  const containerNum = props.data.list.length;
  let containerVolume = totalContainerVol(props.data.list);
  let boxVlume = 0;
  let utilization = '0.00';
  props.data.list.forEach((c) => {
    c.boxes.forEach((box) => {
      boxVlume += box.length * box.width * box.height;
    });
  });
  if (containerVolume) {
    utilization = (boxVlume / containerVolume * 100).toFixed(2);
  }
  return (
    <>
    <div className="font-bold">Summary:</div>
    <div># of {props.containerName}(s): {containerNum}</div>
    <div>Total volume: {containerVolume.toFixed(2)} ({props.cubicUnit})</div>
    <div>Overall utilization: {utilization}%</div>
    </>
  );
}

function CurrentContainerInfo(props) {
  function showBoxTypes(data) {
    let counts = new Map();
    data.list[data.current]?.boxes.forEach(box => {
      if (counts.has(box.id)) {
        counts.set(box.id, counts.get(box.id) + 1);
      } else {
        counts.set(box.id, 1);
      }
    });

    return data.boxTypes
      ?.filter(b => counts.has(b.id))
      .map((b, i) => {
      return <tr key={i} className="">
        <td className="border border-gray-400"
          style={{backgroundColor: convertHexToRGBA(colorPalette(b.id), 50)}}>
          {`${b.id}`}</td>
        <td className="border border-gray-400">{`${b.length.toFixed(2)}x${b.width.toFixed(2)}x${b.height.toFixed(2)}`}</td>
        <td className="border border-gray-400">{`${counts.get(b.id)}`}</td>
        <td className="border border-gray-400">{`${b.total}`}</td>
      </tr>
    });
  }

  let util = props.data.list[props.data.current]?.utilization;
  if (util < 1.0) {
    util *= 100.0;
  }

  return (
    <>
      <div className="font-bold">Current {props.containerName}</div>
      <p>Size: {containerSize(props.data.list[props.data.current]?.container)} ({props.unit})</p>
      <p>Volume: {containerVolume(props.data.list[props.data.current]?.container)} ({props.cubicUnit})</p>
      <p>Utilization: {util?.toFixed(2)}%</p>
      <div className="mt-2 font-bold">Boxes</div>
      <div className="overflow-auto" style={{maxHeight: '300px'}}>
        <table className="border border-gray-400 text-center">
          <thead>
            <tr>
              <td className="border border-gray-400">ID</td>
              <td className="border border-gray-400">Dimensions</td>
              <td className="border border-gray-400">Packed</td>
              <td className="border border-gray-400">Total</td>
            </tr>
          </thead>
          <tbody>
            {showBoxTypes(props.data)}
          </tbody>
        </table>
      </div>
      <p># of boxes: {`${props.data.list[props.data.current]?.boxes?.length}`}</p>
      <p>Volume: {volumeOfBoxes(props.data.list[props.data.current]?.boxes)} ({props.cubicUnit})</p>
    </>
  );
}

function ContainerCaseOptions() {
    const cases = [
      ['Example 1'],
      ['BR0-33'],
      ['BR1-50'],
      ['BR2-30'],
      ['BR3-39'],
      ['BR4-79'],
      ['BR5-85'],
      ['BR6-79'],
      ['BR7-95',],
      ['BR8-27'],
      ['BR9-4'],
      ['BR10-37'],
      ['BR11-85'],
      ['BR12-75'],
      ['BR13-35'],
      ['BR14-54'],
      ['BR15-15'],
    ];
    let options = [];
    cases.forEach((c, i) => {
      const caseName = c[0];
      let file = 'container/' + caseName + '-ALGO_G2LA-true-10000.json';

      if (caseName === 'Example 1') {
        file = 'container-packing/example-1-container1.json';
      }

      options.push(<option key={i} value={file}>{caseName}</option>);
    });
    return options;
}

function AppliedCaseOptions() {
    const cases = [
      ['A', '0010030724'],
      ['B', '0010030240'],
      ['C', '0010028871'],
      ['D', '0010124218'],
      ['E', '0009893614'],
      ['F', '0009914981'],
      ['G', '0009935548'],
      ['H', '0010025708'],
      ['I', '0010024187'],
      ['J', '0009918451'],
      ['K', '0010030723'],
      ['L', '0010285338'],
      ['M', '0009901149'],
      ['N', '0010033439'],
      ['O', '0009988628'],
      ['P', '0010160675'],
      ['Q', '0010173436'],
      ['R', '0009892302'],
      ['S', '0009833104'],
      ['T', '0010186414'],
      ['U', '0009901146'],
      ['W', '0009838009'],
    ];
    let options = [];
    cases.forEach((c, i) => {
      const id = c[0];
      const tkNum = c[1];
      const id1 = id + ' (RedJasper)';
      const id2 = id + ' (Others - ML)';
      const file1 = 'applied/' + tkNum + '_result_rj.json';
      const file2 = 'applied/' + tkNum + '_result.json';

      options.push(<option key={i * 2} value={file1}>{id1}</option>);
      options.push(<option key={i * 2 + 1} value={file2}>{id2}</option>);
    });
    return options;
}

export default ContainerPlanner;
