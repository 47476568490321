import React, {useEffect, useState} from 'react';

function calCost(vol, f, p) {
  return vol / f * p;
}

function Calculator(props) {
  const className = "focus:outline-none text-sm rounded pl-2 w-32 text-red-700 disabled:text-red-100";

  const [originalVol, setOriginalVol] = useState(props.originalVol);
  const [currentVol, setCurrentVol] = useState(props.totalVol);
  const [factor, setFactor] = useState(305);
  const [cost, setCost] = useState(10);

  const totalCost = calCost(currentVol, factor, cost);
  const saved = (Math.abs(originalVol) < 0.00001)? 0: calCost(originalVol - currentVol, factor, cost);

  useEffect(() => {
    setOriginalVol(props.originalVol);
  }, [props.originalVol]);

  useEffect(() => {
    setCurrentVol(props.totalVol);
  }, [props.totalVol]);

	return (
    <>
      <div className="font-bold">Calculator</div>

      <div className="mt-1">Previous Volume:<br/>
      <input type="number" value={originalVol} className={className}
        onChange={e => setOriginalVol(e.target.value)}/></div>

      <div className="mt-1">Current Volume:<br/>
      <input type="number" value={currentVol} className={className}
        onChange={e => setCurrentVol(e.target.value)}/></div>

      <div className="mt-1">Previous - Current:<br/>
      <input type="number" disabled value={(originalVol - currentVol).toFixed(2)} className={className}/>
      </div>

      <div className="mt-2">Volumetric Factor:<br/>
      <input type="number" value={factor} className={className}
        onChange={e => setFactor(e.target.value)}/></div>

      <div className="mt-2">$ per KG:<br/>
      <input type="number" value={cost} className={className}
        onChange={e => setCost(e.target.value)}/></div>

      <div className="mt-2">
        Current Cost: <div className="text-red-700 float-right mr-16">${totalCost.toFixed(2)}</div>
      </div>
      <div className="">
        Cost Saved: <div className="text-red-700 float-right mr-16">${saved.toFixed(2)}</div>
      </div>
    </>
	);
}

export default Calculator;
