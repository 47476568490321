import React from 'react';
import {ReactComponent as UploadIcon} from '../common/icon-upload.svg';

function FileUploader(props) {
  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
    event.target.value = '';
  };

  function genBtn() {
    let btnStyles = "";
    if (props.enabled) {
      btnStyles = "text-white px-4 w-auto h-8 bg-red-600 rounded-full hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none";
    } else {
      btnStyles = "cursor-not-allowed opacity-50 text-white px-4 w-auto h-8 bg-red-600 rounded-full mouse shadow transition ease-in duration-200 focus:outline-none";
    }

      return (
        <button onClick={handleClick} className={btnStyles} disabled={! props.enabled}>
          <UploadIcon className="text-red-100 w-5 h-5 inline-block" style={{position: 'relative', top: '-2px'}} />
        </button>
      );
  }

  return (
    <>
      {genBtn()}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </>
  );
}

export default FileUploader;
